<template>
    <div class="workbench-page">
        <div class="workbench-contents">
            <div class="menu-block">
                <div class="menu-title">
                    <p>货 物</p>
                </div>
                <van-grid :border="false" column-num="4">
                    <van-grid-item class="icon-group" @click="routerTo('CompanyGoodsSendList')">
                        <div class="icon-background" style="background: #ff8d1a">
                            <van-icon name="send-gift-o" />
                        </div>
                        <p>发货</p>
                    </van-grid-item>
                    <van-grid-item class="icon-group" @click="routerTo('ClientGoodsAfterSalesAdd')">
                        <div class="icon-background" style="background: #463be3">
                            <van-icon name="service-o" />
                        </div>
                        <p>售后</p>
                    </van-grid-item>
                    <van-grid-item class="icon-group" @click="routerTo('AlarmPending')">
                        <div class="icon-background" style="background: #e33b3b">
                            <van-icon name="bulb-o" />
                        </div>
                        <p>报警</p>
                    </van-grid-item>
                </van-grid>
            </div>
            <div class="menu-block">
                <div class="menu-title">
                    <p>巡 更</p>
                </div>
                <van-grid :border="false" column-num="4">
                    <van-grid-item class="icon-group" @click="routerTo('ClientPatrolAdd')">
                        <div class="icon-background" style="background: #2a82e4">
                            <van-icon name="tosend" />
                        </div>
                        <p>任务管理</p>
                    </van-grid-item>
                    <van-grid-item class="icon-group" @click="routerTo('ClientPatrolRecording')">
                        <div class="icon-background" style="background: #51877d">
                            <van-icon name="description" />
                        </div>
                        <p>巡更记录</p>
                    </van-grid-item>
                </van-grid>
            </div>
            <div class="menu-block">
                <div class="menu-title">
                    <p>货 物</p>
                </div>
                <van-grid :border="false" column-num="4">
                    <van-grid-item class="icon-group" @click="routerTo('UnitAdd')">
                        <div class="icon-background" style="background: #e3a229">
                            <van-icon name="hotel-o" />
                        </div>
                        <p>单位管理</p>
                    </van-grid-item>
                    <van-grid-item class="icon-group" @click="routerTo('ApprovalManagementExpectation')">
                        <div class="icon-background" style="background: #3ed65f">
                            <van-icon name="completed" />
                        </div>
                        <p>审批管理</p>
                    </van-grid-item>
                    <van-grid-item class="icon-group" @click="routerTo('ClientAccountAdd')">
                        <div class="icon-background" style="background: #7029e3">
                            <van-icon name="manager-o" />
                        </div>
                        <p>帐号管理</p>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let routerTo = (routerName) => {
    $router.push({
        name: routerName
    });
};
</script>

<style lang="scss" scoped>
.workbench-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .workbench-contents {
        padding: 20px;

        .menu-block {
            width: 100%;
            min-height: 130px;
            background: #fff;
            border-radius: 10px;
            padding: 15px 10px;
            margin-bottom: 20px;

            .menu-title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .icon-group {
                display: flex;
                flex-direction: column;

                .icon-background {
                    display: flex;
                    flex-direction: column;
                    font-size: 20px;
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 10px;
                    color: #000;
                }
            }
        }
    }
}
</style>
